





























import { Vue, Component, Prop, Watch  } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveTagUseCase } from '@/usecases'
import { Tag } from '@/models'

@Component

export default class DialogoDeEdicaoDeTag extends Vue {
	@Prop({type: Object})tag?: Tag
	mostra = false
	salvando = false
	saveTagUseCase = new SaveTagUseCase()
	tagEmEdicao: string | undefined = undefined

	mostrar() {
		this.onChangeTag()
		this.mostra = true
	}

	esconder() {
		this.tagEmEdicao = undefined
		this.mostra = false
	}

	salvar() {
		if (!this.tag) return
		if (!this.tagEmEdicao) return

		try {
			this.salvando = true
			this.editarTag()

			const tagSalva = this.tag.id
				? this.saveTagUseCase.update(this.tag)
				: this.saveTagUseCase.create(this.tag)

			this.$emit('confirmado', tagSalva)
			this.mostra = false
			AlertModule.setSuccess('Tag salva com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	editarTag() {
		if(this.tag && this.tagEmEdicao) this.tag.nome = this.tagEmEdicao
	}

	@Watch('tag')
	onChangeTag() {
		this.tagEmEdicao = this.tag?.nome
	}
}
